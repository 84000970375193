import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import * as _ from 'lodash'
import ContactFooter from "../components/ContactFooter";
import ContactForm from "../components/ContactForm";
import * as helper from "../utils";

export const SeoLandingPageAltTemplate = ({title, subtitle, text, list, formName, image, cards, topSection, process, contentComponent}) => {
    const PageContent = contentComponent || Content

    return (
        <React.Fragment>
            <section className={'hero is-large flex-hero'}>
                <div className="hero-body margin-top-0 has-black-overlay overlay-dark"
                     style={{
                         backgroundImage: `url(${
                             !!image.childImageSharp
                                 ? image.childImageSharp.fluid.src
                                 : image
                         })`,
                         backgroundPosition: `center bottom`,
                         backgroundAttachment: `fixed`,
                         backgroundSize: 'cover',
                         backgroundRepeat: 'no-repeat',
                         display: 'flex'
                     }}>
                    <div className={'container'}>
                        <div className={'columns'} style={{
                            height: 'calc(100% - 127px)',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                lineHeight: '1',
                                opacity: '0.99',
                            }} className={'column pt-mobile-6 pb-mobile-5'}>

                                <h3 className="is-size-4-mobile is-size-5-tablet is-size-4-widescreen has-text-weight-semibold pb-0"
                                    style={{
                                        color: 'white',
                                        lineHeight: '1.2',
                                        padding: '0.3rem',
                                        maxWidth: '96%',
                                        // margin: '0 auto',
                                        letterSpacing: '0.5px'
                                    }}>
                                    {subtitle}
                                </h3>
                                <h1 className="h1 is-size-2-mobile is-size-1-tablet is-size-1-widescreen"
                                    style={{
                                        fontFamily: 'Museo',
                                        fontWeight: '500',
                                        color: 'white',
                                        lineHeight: '1.2',
                                        padding: '0.3rem',
                                        whiteSpace: 'pre-line'
                                    }}>
                                    {title}
                                </h1>

                                <div className="is-size-6-mobile is-size-6-tablet is-size-6-widescreen"
                                     style={{
                                         color: 'white',
                                         lineHeight: '1.2',
                                         padding: '0.25rem',
                                         // margin: '0 auto',
                                         letterSpacing: '0.5px'
                                     }}>
                                    <div className={'hero-description-list'}>
                                        {helper.parse(text)}
                                    </div>
                                </div>

                            </div>
                            <div style={{
                                lineHeight: '1',
                                order: '1'
                            }} className={'column pt-2 pb-mobile-6'}>
                                <div className={'card rounded-corners p-3 h-auto'} style={{
                                    backgroundColor: 'rgba(255,255,255,0.96)'
                                }}>
                                    <h3 className={'font-primary is-ligna-font pt-1'}>Contact Us</h3>
                                    <hr className={'my-3'}/>
                                    <ContactForm name={formName}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'half-overlay-top is-hidden-mobile'}>
                <div className={'container'}>
                    <div className="columns">
                        {_.map(cards, service => {
                            return <div className="column">
                                <div className={'card no-padding white-bg rounded-corners'} style={{maxWidth: '500px'}}>
                                    <div className={'card-header has-padding white-bg float-icon pb-0'}>
                                        <div className={'icon-circle-container'}>
                                            <div className={'icon-circle primary-bg'}>
                                                <i className={service.icon + (' font-white')}></i>
                                            </div>
                                        </div>
                                        <h3 className="card-title font-primary has-text-centered">
                                            {service.title}
                                        </h3>
                                        <p className="card-subtitle has-text-centered">{service.subtitle}</p>
                                    </div>
                                    <div className={'card-body pt-2'}>
                                        <p className={'card-first-para'}>{service.text}</p>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
            <section className={'section dark-green-bg border-top-primary'}>
                <h4 className={'has-text-centered font-primary'}>/ &nbsp;{topSection.title} &nbsp;/</h4>
                <div className={'centered-text-container line-left font-white'}>
                    <p className={'text-first-para font-white'}>{topSection.firstPara}</p>
                    <p className={'text'}>{topSection.text}</p>
                </div>
            </section>

            {_.map(process.stages, (stage, index) => {
                return <section className={'section light-green-bg border-top-primary'}>
                    <div className={'container'}>
                        {index === 0 ?
                            <h4 className={'has-text-centered font-primary'}>/ &nbsp;{process.title} &nbsp;/</h4> : ''}
                        <div className={'columns mobile-margin-bottom my-4'}>
                            <div className={'column is-3 d-flex'}>
                                <div className={'stage-title-container my-auto'}>
                                    <h3 className={'font-primary'}>{stage.title}</h3>
                                    <h5 className={'font-primary'}>{stage.subtitle}</h5>
                                </div>
                            </div>
                            {_.map(stage.children, child => {
                                return <div className="column">
                                    <div className={'card no-padding white-bg rounded-corners'}>
                                        <div className={'card-header has-padding primary-bg float-icon'}>
                                            <div className={'icon-circle-container'}>
                                                <div className={'icon-circle border-primary'}>
                                                    <i className={child.icon}></i>
                                                </div>
                                            </div>
                                            <h3 className="card-title font-white has-text-centered px-3">
                                                {child.title}
                                            </h3>
                                            <p className="card-subtitle has-text-centered">{child.subtitle}</p>
                                        </div>
                                        <div className={'card-body'}>
                                            <p className={'card-first-para'}>{child.tagline}</p>
                                            <p>{child.text}</p>
                                        </div>
                                        <div className={'card-footer'}>
                                            <a className={'font-mint'}
                                               href={child.link}>{_.includes(child.link, '/contact') ? 'Contact Us' : 'More Information'}</a>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </section>
            })}
            <ContactFooter/>
        </React.Fragment>
    )
}

SeoLandingPageAltTemplate.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

const SeoLandingPageAlt = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout phone={'01284 598013'} email={'team@lignaconsultancy.co.uk'}>
            <SeoLandingPageAltTemplate
                contentComponent={HTMLContent}
                title={frontmatter.title}
                subtitle={frontmatter.subtitle}
                text={frontmatter.text}
                list={frontmatter.list}
                formName={frontmatter.formName}
                image={frontmatter.image}
                cards={frontmatter.cards}
                topSection={frontmatter.topSection}
                process={frontmatter.process}
            />
        </Layout>
    )
}

SeoLandingPageAlt.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SeoLandingPageAlt

export const SeoLandingPageAltQuery = graphql`
  query SeoLandingPageAlt($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        text
        list
        formName
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cards {
            title
            icon
            text
        }
        topSection {
            title
            firstPara
            text
        }
        process {
            title
            stages {
                title
                subtitle
                children {
                    title
                    subtitle
                    icon
                    text
                    link
                }
            }
        }
      }
    }
  }
`
